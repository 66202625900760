<template>
 		<div id="app" class="main">
			<div class="loader" id="loader">
				loading
				<div id="circle"></div>
			</div>
			<a href="#"><div class="curtain" :class="visCurtain()" @click="resetCurtain()"></div></a>
        	<div class="buttons">
					 <div class="button about" @click="showAbout()"><a href="#">about</a></div> 
					 <div class="button home"><a href="#">tmsn</a></div>
					 <div class="button archive"><a href="http://archive.takemesomewherenice.be/">archive</a></div>
			</div>
			<div class="grid" v-images-loaded.on.progress="layout">
				<div class="cell ccc"><div class="cellOverlay"><a href="https://ccc.takemesomewherenice.be" target="_blank"><img class="imgcell" src="images/cccCoverwGrid.jpg"></a></div></div>
				<div class="cell rea" @click="showRea()"><div class="cellOverlay"><a href="#"><img class="imgcell"  src="images/resCover.jpg"></a></div></div>
				<div class="cell pp1" @click="showPp()"><div class="cellOverlay"><a href="#"><img class="imgcell"  src="images/ppCover1.jpg"></a></div></div>
				<div class="cell pp2" @click="showPp()"><div class="cellOverlay"><a href="#"><img class="imgcell" src="images/ppCover2.jpg"></a></div></div>
				<div class="cell cnstrcn1" @click="showCnstrcn()"><div class="cellOverlay"><a href="#"><img class="imgcell"  src="images/cnstrcnCover1.jpg"></a></div></div>
				<div class="cell cnstrcn2" @click="showCnstrcn()"><div class="cellOverlay"><a href="#"><img class="imgcell"  src="images/cnstrcnCover2.jpg"></a></div></div>
				<div class="cell nts" @click="showNts()"><div class="cellOverlay"><a href="#"><img class="imgcell" src="images/ntsCover.jpg"></a></div></div>
                <div class="cell rnd" @click="showRnd()"><div class="cellOverlay"><a href="#"><img class="imgcell" src="images/rndCover.jpg"></a></div></div>
				<div class="cell tlf" @click="showTlf()"><div class="cellOverlay"><a href="#"><img class="imgcell" src="images/tlfCover.jpg"></a></div></div>
				<div class="cell tlfmp" @click="showTlf()"><div class="cellOverlay"><a href="#"><img class="imgcell" src="images/tlfMockUp-01.jpg"></a></div></div>				 
				<div class="cell about" @click="showAbout()"><div class="cellOverlay"><a href="#"><img class="imgcell" src="images/logo_square92.jpg"></a></div></div>
	     		<div class="text about" :class="visAbout()">
					<p class="title">about</p>
					<p class="p1">contact</p>
					<p class="p4">stijn van der linden</p>
					<p class="p4"><a href="mailto:info@takemesomewherenice.be" target="_blank">info@takemesomewherenice.be</a></p>
					<p class="p4">mechelen, belgium</p>
					<p class="p6"><a href="https://www.instagram.com/tmsn_be/" target="_blank"><img src="images/insta.png" style="width:25px;height:25px;border:0;"></a></p>
					<p class="p4">subscribe to the <a href="https://mailchi.mp/5edc04990242/subscription" target="_blank">newsletter</a>.</p>
					<p class="p1">exhibitions</p>
					<p class="p3"><a href="http://theworldasifoundit.be/kunstenaars.php?artist=stijn%20van%20der%20linden" target="_blank"> 2013 | the World as I found it, Group Exhibition, Leuven. </a><br></p>
					<p class="p3"><a href="http://theworldasifoundit.be/kunstenaars.php?artist=stijn%20van%20der%20linden" target="_blank"> 2014 | the World as I found it, Group Exhibition, Bierbeek. </a><br></p>
					<p class="p3"><a href="http://fotofilmic.com/ff14-exhibition-preview/" target="_blank">2014 | Fotofilmic, 5th Place Lomography &amp; Adox Honorable Mention, Group Exhibition.</a><br></p>
					<p class="p3"><a href="https://www.facebook.com/SummerSchoolRealEstateArchitecture"> 2017 | Real Estate Architecture Summer School Group Exhibition, WTC #1, Brussels. </a><br></p>
					<p class="p3"><a href="https://mentormentor.be/traject/galerie-trjct-02/"> 2018 | mentormentor, Group Exhibition, Blanco, Ghent. </a><br></p>
					<p class="p3"><a href="https://archive.takemesomewherenice.be/1185-2/"> 2019 | Photobook Week Aarhus 2019, Walking Tour & Book Launch, dokk1, Aarhus. </a></p>
					<p class="p1">books</p>
					<p class="p3">2019 | <a href="https://ccc.takemesomewherenice.be" target="_blank">an essay on the concave city corner</a> [<a href="https://vimeo.com/362842537">video</a>]</p>
					<ul class="p3">
						Dummy Awards:
						<li class="p3"><a href="http://issp.lv/en/news/self-publish-riga-2018-winners-announced"> Self Publish Riga 2018, Top 10</a> & <a href="https://www.facebook.com/selfpublishriga/posts/2277691738914658&width=500">Audience award</a>;</li>
						<li class="p3"><a href="http://www.bjp-online.com/2018/09/unseen-dummy-award-shortlist-announced/">Unseen Dummy Award 2018, Shortlisted</a>;</li>
						<li class="p3">Winner of the <a href="http://photobookweek.org/award/">Photobook Week Aarhus 2018 Dummy Award</a>.</li>
						<br>
						Book Awards:
						<li class="p3"><a href="http://theangrybat.com/portfolio/photobook_award-2020/">Maribor Photobook Award 2020, Honorable mention</a>;</li>			
                        <li class="p3"><a href="https://www.phe.es/conoce-a-los-seleccionados-del-premio-al-mejor-libro-de-fotografia-del-ano-photoespana-2020/">PHotoEspaña 2020, Mejor Libro De Fotografia Del Año, Finalista Categoría Internacional</a>.</li>	
						<br>
						Book Reviews:
						<li class="p3"><a href="http://kasselerfotobuchblog.de/um-die-ecke-gedacht/">2019 | Kasseler Fotobuchblog, review</a>;</li>
						<li class="p3"><a href="https://cphmag.com/form-photobook-revisited/">2020 | Conscientious Photography Magazine, review</a>;</li>
						<li class="p3"><a href="https://collectordaily.com/stijn-van-der-linden-essay-on-the-concave-city-corner/?fbclid=IwAR0aw27y6wBUeFcwd3I3yX41u65O6BE-1aUjk8pK4RTaXtxlyVZ4q68eGeg">2020 | Collector Daily, review</a>.</li>
					</ul>
					<p class="p3" style="text-indent: 40px;">
					The book is available in the <a href="https://shop.takemesomewherenice.be/product/an-essay-on-the-concave-city-corner/">TMSN Shop</a>, <a href="https://tipi-bookshop.be/shop/ccc-by-stijn-van-der-linden/">Tipi Bookshop</a>
					and at <a href="http://reminders-project.org/rps/anessayontheconcavecitycorner_salejp/?fbclid=IwAR3S9g4Ri4cgmsfXkKS8XE9t2KwoYtLrQp4MafToVj7ryTT9DOzwaCI-2X4">Reminders Photography Stronghold</a>.
					</p>
					<p class="p3">&nbsp;</p>
					<p class="p3">2022 | <a href="https://www.takemesomewherenice.be" target="_blank">they look for</a></p>
					<p class="p3" style="text-indent: 40px; margin-top: 13.3333px;">
						The book is available at the <a href="https://shop.takemesomewherenice.be/">TMSN Shop</a>, <a href="https://www.buroform.be/shop/boeken/they-look-for-stijn-van-der-linden/">Buroform</a> and soon at <a href="https://tipi-bookshop.be/shop/">Tipi Bookshop</a>.</p>		
					<p class="p1">publications</p>
					<p class="p3"> <a href="http://derivations.be/archives/numero-4/" target="_blank">2017 | dérivations, Numéro 4. </a></p>
					<p class="p3"> <a href="https://equivalence-shop.com/epages/436a6e69-a9a6-4e16-b46c-50e03f48687c.sf/en_GB/?ObjectPath=/Shops/436a6e69-a9a6-4e16-b46c-50e03f48687c/Products/EP104">2019 | European Photography Magazine, issue 104.</a></p>
					<p></p><p class="p1">featured online</p>
					<p class="p3"><a href="http://www.fstopmagazine.com/archive.html" target="_blank">2011 | f-stop magazine, Issue 47, Group Exhibit. </a></p>
					<p class="p3"><a href="http://www.hafny.org/group-show-39-p22/" target="_blank">2014 | humble arts foundation, Group Show 39. </a></p>
					<p class="p3"><a href="http://browniephoto.be/an-essay-on-the-concave-city-corner-stijn-van-der-linden/" target="_blank">2018 | BrowniE, Coups de coeur du Liège Photobook festival</a>;</p>
					<p class="p3"><a href="https://fkmagazine.lv/2018/06/01/self-publish-riga-2018-top-10/" target="_blank">2018 | FK Magazine, Self Publish Riga 2018 Top 10</a>;</p>
					<p class="p3"><a href="http://belphotobooks.org/book/an-essay-on-the-concave-city-corner/">2019 | Belgian Platform for Photobooks, catalogue</a>.</p>
					<p class="p5">texts by katrien vanherck<br>
					photographs, research and analysis are © stijn van der linden, 2022</p>
					<div class="button back" @click="showAbout()"><a href="#">back</a></div>
				</div>
				<div class="text rea" :class="visRea()">
					<p class="title">brussels north district: the office tower</p>
					<div class="slideshow-container">
					  <div class="slides Rea" @click="plusSlides(1,0)">
						<img class="imgSlide" src="images/rea/rea001.jpg">
					  </div>
					  <div class="slides Rea" @click="plusSlides(1,0)">
						<img class="imgSlide" src="images/rea/rea002.jpg">
					  </div>
					  <div class="slides Rea" @click="plusSlides(1,0)">
						<img class="imgSlide" src="images/rea/rea003.jpg">
					  </div>
					  <div class="slides Rea" @click="plusSlides(1,0)">
						<img class="imgSlide" src="images/rea/rea004.jpg">
					  </div>
					  <div class="slides Rea" @click="plusSlides(1,0)">
						<img class="imgSlide" src="images/rea/rea005.jpg">
					  </div>
					  <div class="slides Rea" @click="plusSlides(1,0)">
						<img class="imgSlide" src="images/rea/rea006.jpg">
					  </div>
					  <div class="slides Rea" @click="plusSlides(1,0)"> 
						<img class="imgSlide" src="images/rea/rea007.jpg">
					  </div>
					  <div class="slides Rea" @click="plusSlides(1,0)">
						<img class="imgSlide" src="images/rea/rea008.jpg">
					  </div>
					  <div class="slides Rea" @click="plusSlides(1,0)">
						<img class="imgSlide" src="images/rea/rea009.jpg">
					  </div>
					  <div class="slides Rea" @click="plusSlides(1,0)">
						<img class="imgSlide" src="images/rea/rea010.jpg">
					  </div>
					  <div class="slides Rea" @click="plusSlides(1,0)">
						<img class="imgSlide" src="images/rea/rea011.jpg">
					  </div>
					</div>
			
					<div style="text-align:center">
     				  <div class="prev" @click="plusSlides(-1,0)">&#10094;</div>
					  <span class="dot Rea" @click="currentSlide(1,0)"></span>
					  <span class="dot Rea" @click="currentSlide(2,0)"></span>
					  <span class="dot Rea" @click="currentSlide(3,0)"></span>
					  <span class="dot Rea" @click="currentSlide(4,0)"></span>
					  <span class="dot Rea" @click="currentSlide(5,0)"></span>
					  <span class="dot Rea" @click="currentSlide(6,0)"></span>
					  <span class="dot Rea" @click="currentSlide(7,0)"></span>
					  <span class="dot Rea" @click="currentSlide(8,0)"></span>
					  <span class="dot Rea" @click="currentSlide(9,0)"></span>
					  <span class="dot Rea" @click="currentSlide(10,0)"></span>
					  <span class="dot Rea" @click="currentSlide(11,0)"></span>					  
					  <div class="next" @click="plusSlides(1,0)">&#10095;</div>	 
					</div> 

					<p class="p1"></p>
					<p class="p2">A project commissioned by 
					<a href="https://www.facebook.com/SummerSchoolRealEstateArchitecture" target="_blank">Real Estate Architecture</a>, 
					it explores the legacy of architectures built by private developers during the 1960s and 1970s. Through workshops and 
					lectures, publications and exhibitions, it aims to reveal the transformation potentials of these ubiquitous developments. 
					Rooted in the contexts of three different Belgian cities, the research project reconsiders three specific architecture 
					typologies: the apartment building, the office tower and the commercial centre.</p>
					<p class="p2">In the framework of their 2017 summer school: the office tower, I photographed the Brussels North District from 
					the street, while <a href="https://antoineespinasseau.com/" target="_blank">Antoine Espinasseau</a> photographed inside current or defunct
					offices. During the summer school the photographs were exhibited in a joint exhibition on the first level of the
					<a href="http://www.labnorth.be/en/locations/the-world-trade-center-tower-1">WTC Tower 1 building</a> in Brussels.</p>
					<div class="button back" @click="showRea()"><a href="#">back</a></div>
				</div>
				<div class="text pp" :class="visPp()">
					<p class="title">private - public</p>
					<div class="slideshow-container">
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp001.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp002.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp003.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp004.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp005.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp006.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)"> 
						<img class="imgSlide" src="images/pp/pp007.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp008.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp009.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp010.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp011.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp012.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp013.jpg">
					  </div>
					  <div class="slides Pp" @click="plusSlides(1,1)">
						<img class="imgSlide" src="images/pp/pp014.jpg">
					  </div>
					</div>
					<div style="text-align:center">
     				  <div class="prev" @click="plusSlides(-1,1)">&#10094;</div>
					  <span class="dot Pp" @click="currentSlide(1,1)"></span>
					  <span class="dot Pp" @click="currentSlide(2,1)"></span>
					  <span class="dot Pp" @click="currentSlide(3,1)"></span>
					  <span class="dot Pp" @click="currentSlide(4,1)"></span>
					  <span class="dot Pp" @click="currentSlide(5,1)"></span>
					  <span class="dot Pp" @click="currentSlide(6,1)"></span>
					  <span class="dot Pp" @click="currentSlide(7,1)"></span>
					  <span class="dot Pp" @click="currentSlide(8,1)"></span>
					  <span class="dot Pp" @click="currentSlide(9,1)"></span>
					  <span class="dot Pp" @click="currentSlide(10,1)"></span>
					  <span class="dot Pp" @click="currentSlide(11,1)"></span>		
					  <span class="dot Pp" @click="currentSlide(12,1)"></span>
					  <span class="dot Pp" @click="currentSlide(13,1)"></span>	
					  <span class="dot Pp" @click="currentSlide(14,1)"></span>	  
					  <div class="next" @click="plusSlides(1,1)">&#10095;</div>	 
					</div> 
					<p class="p1"></p>
					<p class="p2">The atmosphere in the streets becomes more and more toxic for photographers. 
					People are protesting, verbally or even physically, against themselves or their property being photographed on the street. These protests are 
					strangely in contrast with the fact that people are throwing images of themselves in private settings online with little regard for privacy settings 
					or privacy laws. Perhaps they don’t mind the aspect of their privacy being made public so much after all, as long as they have control over what and
					how? Either way, there is a clear duality in the way people perceive their privacy that is not only a new, interesting aspect of the world in the streets 
					today but even something that can no longer be ignored.</p>
					<div class="button back" @click="showPp()"><a href="#">back</a></div>
				</div>
				<div class="text cnstrcn" :class="visCnstrcn()">
					<p class="title">construction</p>
					<div class="slideshow-container">
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn001.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn002.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn003.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn004.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn005.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn006.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)"> 
						<img class="imgSlide" src="images/cnstrcn/cnstrcn007.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn008.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn009.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn010.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn011.jpg">
					  </div>
					  <div class="slides Cnstrcn" @click="plusSlides(1,2)">
						<img class="imgSlide" src="images/cnstrcn/cnstrcn012.jpg">
					  </div>
					</div>
			
					<div style="text-align:center">
     				  <div class="prev" @click="plusSlides(-1,2)">&#10094;</div>
					  <span class="dot Cnstrcn" @click="currentSlide(1,2)"></span>
					  <span class="dot Cnstrcn" @click="currentSlide(2,2)"></span>
					  <span class="dot Cnstrcn" @click="currentSlide(3,2)"></span>
					  <span class="dot Cnstrcn" @click="currentSlide(4,2)"></span>
					  <span class="dot Cnstrcn" @click="currentSlide(5,2)"></span>
					  <span class="dot Cnstrcn" @click="currentSlide(6,2)"></span>
					  <span class="dot Cnstrcn" @click="currentSlide(7,2)"></span>
					  <span class="dot Cnstrcn" @click="currentSlide(8,2)"></span>
					  <span class="dot Cnstrcn" @click="currentSlide(9,2)"></span>
					  <span class="dot Cnstrcn" @click="currentSlide(10,2)"></span>
					  <span class="dot Cnstrcn" @click="currentSlide(11,2)"></span>		
					  <span class="dot Cnstrcn" @click="currentSlide(12,2)"></span>
					  <div class="next" @click="plusSlides(1,2)">&#10095;</div>	 
					</div> 

					<p class="p1"></p>
					<p class="p2" style="text-align:center;">the future lies with the city.</p>
					<div class="button back" @click="showCnstrcn()"><a href="#">back</a></div>
				</div>
				<div class="text nts" :class="visNts()">
					<p class="title">there is nothing to see here</p>
					<div class="slideshow-container-wide">
					  <div class="slides wide Nts" @click="plusSlides(1,3)">
						<img class="imgSlideWide" src="images/nts/nts001.jpg">
					  </div>
					  <div class="slides wide Nts" @click="plusSlides(1,3)">
						<img class="imgSlideWide" src="images/nts/nts002.jpg">
					  </div>
					  <div class="slides wide Nts" @click="plusSlides(1,3)">
						<img class="imgSlideWide" src="images/nts/nts003.jpg">
					  </div>
					  <div class="slides wide Nts" @click="plusSlides(1,3)">
						<img class="imgSlideWide" src="images/nts/nts004.jpg">
					  </div>
					  <div class="slides wide Nts" @click="plusSlides(1,3)">
						<img class="imgSlideWide" src="images/nts/nts005.jpg">
					  </div>
					  <div class="slides wide Nts" @click="plusSlides(1,3)">
						<img class="imgSlideWide" src="images/nts/nts006.jpg">
					  </div>
					  <div class="slides wide Nts" @click="plusSlides(1,3)"> 
						<img class="imgSlideWide" src="images/nts/nts007.jpg">
					  </div>
					  <div class="slides wide Nts" @click="plusSlides(1,3)">
						<img class="imgSlideWide" src="images/nts/nts008.jpg">
					  </div>
					  <div class="slides wide Nts" @click="plusSlides(1,3)">
						<img class="imgSlideWide" src="images/nts/nts010.jpg">
					  </div>
					</div>
			
					<div style="text-align:center">
     				  <div class="prev" @click="plusSlides(-1,3)">&#10094;</div>
					  <span class="dot Nts" @click="currentSlide(1,3)"></span>
					  <span class="dot Nts" @click="currentSlide(2,3)"></span>
					  <span class="dot Nts" @click="currentSlide(3,3)"></span>
					  <span class="dot Nts" @click="currentSlide(4,3)"></span>
					  <span class="dot Nts" @click="currentSlide(5,3)"></span>
					  <span class="dot Nts" @click="currentSlide(6,3)"></span>
					  <span class="dot Nts" @click="currentSlide(7,3)"></span>
					  <span class="dot Nts" @click="currentSlide(8,3)"></span>
					  <span class="dot Nts" @click="currentSlide(9,3)"></span>
					  <span class="dot Nts" @click="currentSlide(10,3)"></span>
					  <div class="next" @click="plusSlides(1,3)">&#10095;</div>	 
					</div> 
					<p class="p1"></p>
					<p class="p2">In every place in the world, there are essential locations, buildings, 
					monuments, statues or even atmospheres that everyone knows, everyone wants to see and everyone tries to capture in a photograph. Tens of thousands 
					of variations of these photographs are circulating on the web. People are digesting these images in a high-speed tempo and are quick to dismiss what they
					have seen before so many times. At the verge of the 20th century, painters as Manet, Cézanne or Picasso were searching for new, interesting 
					ways to depict reality in paintings	in response to the rise of photography. Similarly, photographers or visual artists now are seeking new ways to show the world 
					in response to the increased availability of technology and the internet as a means of distribution. The challenge is to find shots that still capture 
					the viewers’ attention, that force them to look at a known concept with a new perspective and that make them more consciously take in and 
					experience the photographs.</p>
					<div class="button back" @click="showNts()"><a href="#">back</a></div>
				</div>
				<div class="text rnd" :class="visRnd()">
					<p class="title">random three</p>
					<div class="slideshow-container">
					  <div class="slides rnd" @click="plusSlides(1,4)">
						<img class="imgSlide" src="images/rnd/rnd-city-01.jpg">
					  </div>
					  <div class="slides rnd" @click="plusSlides(1,4)">
						<img class="imgSlide" src="images/rnd/rnd-city-02.jpg">
					  </div>
					  <div class="slides rnd" @click="plusSlides(1,4)">
						<img class="imgSlide" src="images/rnd/rnd-city-03.jpg">
					  </div>
					</div>
					<div style="text-align:center">
     				  <div class="prev" @click="plusSlides(-1,4)">&#10094;</div>
					  <span class="dot rnd" @click="currentSlide(1,4)"></span>
					  <span class="dot rnd" @click="currentSlide(2,4)"></span>
					  <span class="dot rnd" @click="currentSlide(3,4)"></span>
					  <div class="next" @click="plusSlides(1,4)">&#10095;</div>	 
					</div> 
					<p class="p1"></p>
					<p class="p2">As a photographer, the city is an important focus of my work. Last year I published a photobook titled an essay on the concave city corner, 
                    the reward of a long and intensive process of photographing, conceptualising, studying, designing and writing. After spending a lot of time and energy on 
                    that single project, I needed something less complex, for a change: bringing random images together, without too much contemplation or purpose. This collection of 3 photographs involves the city. Prints are available at the <a href="https://shop.takemesomewherenice.be">TMSN shop</a>.</p>
					<div class="button back" @click="showRnd()"><a href="#">back</a></div>
				</div>     
				<div class="text tlf" :class="visTlf()">
					<p class="title">they look for</p>
					<div class="slideshow-container-wide">
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-01.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlfMockUp-01.jpg">
					  </div>					  
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-02.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-03.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-04.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-05.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-06.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-07.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-08.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-09.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-10.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-11.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-12.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-13.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-14.jpg">
					  </div>
					  <div class="slides wide tlf" @click="plusSlides(1,5)">
						<img class="imgSlideWide" src="images/tlf/tlf-15.jpg">
					  </div>
					</div>
					<div style="text-align:center">
     				  <div class="prev" @click="plusSlides(-1,5)">&#10094;</div>
					  <span class="dot tlf" @click="currentSlide(1,5)"></span>
					  <span class="dot tlf" @click="currentSlide(2,5)"></span>
					  <span class="dot tlf" @click="currentSlide(3,5)"></span>
					  <span class="dot tlf" @click="currentSlide(4,5)"></span>
					  <span class="dot tlf" @click="currentSlide(5,5)"></span>
					  <span class="dot tlf" @click="currentSlide(6,5)"></span>
					  <span class="dot tlf" @click="currentSlide(7,5)"></span>
					  <span class="dot tlf" @click="currentSlide(8,5)"></span>
					  <span class="dot tlf" @click="currentSlide(9,5)"></span>
					  <span class="dot tlf" @click="currentSlide(10,5)"></span>
					  <span class="dot tlf" @click="currentSlide(11,5)"></span>
					  <span class="dot tlf" @click="currentSlide(12,5)"></span>
					  <span class="dot tlf" @click="currentSlide(13,5)"></span>
					  <span class="dot tlf" @click="currentSlide(14,5)"></span>
					  <span class="dot tlf" @click="currentSlide(15,5)"></span>
					  <span class="dot tlf" @click="currentSlide(16,5)"></span>					  
					  <div class="next" @click="plusSlides(1,5)">&#10095;</div>	 
					</div> 
					<p class="p1">they look for animals, but all they find are trees</p>
					<p class="p2">
						While visiting Corcovado and other national parks in Costa Rica, I noticed other visitors were mostly interested in finding and spotting species of the animal kingdom. He found that they didn’t give the best part of the nature in those places the attention it deserved: the immense richness of species of the plant kingdom. Especially the amount of different tree species that can be found was ungraspable.</p>
					<p class="p2">
						During the visits, hikers are guided so they won’t get lost but I ended up getting lost while editing the photographs years after actually being there: trying and retrying endless combinations of photographs or parts of photographs while following the directions of plant growth or their shades of green. It was an extension of the journey and an interesting challenge to convey this experience into a publication.</p>
					<p class="p2">
						All photographs were made in 2016 at Corcovado National Park, Cahuita National Park, Monteverde Cloud Forest and the Tortoguero canal in Costa Rica and contain animals of 6 families and trees of potentially more than 50 families. The publication is published and printed by <a href="https://www.buroform.be">Buroform</a> as part of the Café Cliché event  <a href="https://www.cafecliche.be/evenementen/2022/lets-talk-about-books-baby/">"Let's Talk About Books, Baby"</a>. It is available at the <a href="https://shop.takemesomewherenice.be/">TMSN Shop</a>, <a href="https://www.buroform.be/shop/boeken/they-look-for-stijn-van-der-linden/">Buroform</a> and soon at <a href="https://tipi-bookshop.be/shop/">Tipi Bookshop</a>.</p>
					<div class="button back" @click="showTlf()"><a href="#">back</a></div>
				</div>				           
			</div>
		</div>
</template>

<script>
    import imagesLoaded from 'vue-images-loaded';
    import VRuntimeTemplate from "v-runtime-template";

    export default {
        name: 'App',
        components: {
            imagesLoaded, 
            VRuntimeTemplate,
        },   
	data: function(){
		return{
			curtain: false,
			about: false,
			rea: false,
			pp: false,
			cnstrcn: false,
			nts: false,
            rnd: false,
			tlf: false,
            count: 0,
			slideIndex: [1,1,1,1,1,1],
			slideId: ["slides Rea", "slides Pp", "slides Cnstrcn", "slides wide Nts", "slides rnd","slides tlf"],
			dotsId: ["dot Rea", "dot Pp", "dot Cnstrcn", "dot Nts", "dot rnd", "dot tlf"]
		}	
	},
	methods: {
		getOptions: function(){
		},	
		resetCurtain: function(){
			this.curtain = false;
			this.about = false;
			this.rea = false;
			this.pp = false;
			this.cnstrcn = false;
			this.nts = false;	
            this.rnd = false;
			this.tlf = false;
			this.slideIndex = [1,1,1,1,1,1];
		},
		visCurtain: function(){
			if (this.curtain == true){
				return "visible";
			} else {
				return "hidden";
			}    	
		},
		showAbout: function(){
			if (this.about == true){
				this.curtain = false;
				this.about = false;
			} else {
				this.curtain = true;
				this.about = true;
			} 			
		},
		visAbout: function(){
			if (this.about == true){
				return "visible";
			} else {
				return "hidden";
			}    	
		},
		showPp: function(){
			if (this.pp == true){
				this.curtain = false;
				this.pp = false;
				this.showSlides(1,1);
			} else {
				this.curtain = true;
				this.pp = true;
				this.showSlides(1,1);
			} 			
		},
		visPp: function(){
			if (this.pp == true){
				return "visible";
			} else {
				return "hidden";
			}    	
		},
		showRea: function(){
			if (this.rea == true){
				this.curtain = false;
				this.rea = false;
				this.showSlides(1,0);
			} else {
				this.curtain = true;
				this.rea = true;
				this.showSlides(1,0);
			} 			
		},		
		visRea: function(){
			if (this.rea == true){
				return "visible";
			} else {
				return "hidden";
			}    	
		},
		showCnstrcn: function(){
			if (this.cnstrcn == true){
				this.curtain = false;
				this.cnstrcn = false;
				this.showSlides(1,2);
			} else {
				this.curtain = true;
				this.cnstrcn = true;
				this.showSlides(1,2);
			} 			
		},		
		visCnstrcn: function(){
			if (this.cnstrcn == true){
				return "visible";
			} else {
				return "hidden";
			}    	
		},
		showNts: function(){
			if (this.nts == true){
				this.curtain = false;
				this.nts = false;
				this.showSlides(1,3);
			} else {
				this.curtain = true;
				this.nts = true;
				this.showSlides(1,3);
			} 			
		},		
		visNts: function(){
			if (this.nts == true){
				return "visible";
			} else {
				return "hidden";
			}    	
		},
		showRnd: function(){
			if (this.rnd == true){
				this.curtain = false;
				this.rnd = false;
				this.showSlides(1,4);
			} else {
				this.curtain = true;
				this.rnd = true;
				this.showSlides(1,4);
			} 			
		},		
		visRnd: function(){
			if (this.rnd == true){
				return "visible";
			} else {
				return "hidden";
			}    	
		},      
		showTlf: function(){
			if (this.tlf == true){
				this.curtain = false;
				this.tlf = false;
				this.showSlides(1,5);
			} else {
				this.curtain = true;
				this.tlf = true;
				this.showSlides(1,5);
			} 			
		},		
		visTlf: function(){
			if (this.tlf == true){
				return "visible";
			} else {
				return "hidden";
			}    	
		},   		  
		plusSlides: function(n,no) {
		  this.showSlides(this.slideIndex[no] += n,no);
		},
		currentSlide: function(n,no) {
		  this.showSlides(this.slideIndex[no] = n,no);
		},
		showSlides: function(n,no) {
		  var i;
		  var slides = document.getElementsByClassName(this.slideId[no]);
		  var dots = document.getElementsByClassName(this.dotsId[no]);
		  if (n > slides.length) {this.slideIndex[no] = 1}
		  if (n < 1) {this.slideIndex[no] = slides.length}
		  for (i = 0; i < slides.length; i++) {
			  slides[i].style.display = "none";
		  }
		  for (i = 0; i < dots.length; i++) {
			  dots[i].className = dots[i].className.replace(" active", "");
		  }
		  slides[this.slideIndex[no]-1].style.display = "block";
		  dots[this.slideIndex[no]-1].className += " active";
		}, 
        layout: function() {
                document.getElementById("loader").style.visibility = "hidden";	
                document.body.style.overflow = "auto";	
          }        
	},
	directives: {
        imagesLoaded
	},
    created: function(){

    }
}
</script>

<style>
body,html {
    margin: 0;
    padding: 0;
}


body {
  font-family: 'Roboto', sans-serif;
  font-size: 12pt;
  background-color: #eee;
  z-index: 0;
  color: #555;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.25;
}

.main{
	z-index: 0;
	max-width: 1550px;
	margin: 0 auto;
	width:100%;
}

a{
    text-decoration: none;
}

/* menu */

.buttons{
	display: grid;
	grid-gap: 0px;
	grid-template-columns: 33.3333% 33.3334% 33.3333%;
	grid-template-areas:
	"about home archive";
	margin: 0 auto;
    height: auto;
    z-index: 1;
    background-color: transparent;
}

.button.about {
	grid-area: about;
	text-align: left;
}
.button.home {
	grid-area: home;
	text-align: center;

}
.button.archive {
	grid-area: archive;
	text-align: right;
}

.button {
  font-size: 200%;
  font-weight: 400;
  margin-top: 13.3333px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: 'Oswald', sans-serif;
  color: #666;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
}

.button.back {
 margin-top: 26.6666px;
 margin-bottom: 26.6666px;
 font-size: 20pt;
 font-weight: 400;
 font-family: 'Oswald',sans-serif;
 text-align: center;
}

.button a{
	color: #666;
}

.button a:hover{
	color:#999;
}

.button.back a:hover{
	color:#999;
	background: #eee;
	border-bottom: 0px;	
}

/* content */

.imgcell{
	width: 100%;
}

.cell{
  background-color: transparent;
  color: #fff;
  padding: 5px;
}

.curtain.visible {
    height: 100%;
    width: 100%;
	left: 0px;
    position: fixed;
    z-index: 10;
    background-color: rgba(0,0,0, 0.75);
    overflow-x: hidden;
    overflow-y: hidden;
}
.curtain.hidden{
	width: 0;
}

.text a{
	color: #555;
}

.text a:hover{
	color: #555;
	background-color: #999;
}

p.title{
    font-size: 200%;
    display: block;
    font-weight: 400;
    line-height: 1.5;
    padding: 0px;
    margin: 0px;
    color: #666;
    text-align: center;
    font-family: 'Oswald', Verdana, sans-serif;
}

p.p2 a{
	border-bottom: 1px dotted; 
}

p.p3 a{
	border-bottom: 1px dotted; 
}

p.p4 a{
	border-bottom: 1px dotted; 
}

p.p1 {
 font-size: 18pt; 
 font-weight:300;
 font-family: 'Oswald', Verdana, sans-serif;
 margin-top: 39.9999px;
}

p.p2 {
 font-size: 13pt;

}

p.p3 {
 font-size: 13pt;
 margin-top: -0.5pt;
 margin-bottom: -0.5pt;	
}

ul.p3 {
 font-size: 13pt;
 margin-top: 7.5pt;
}

li.p3 {
 content:"> ";
 list-style: none;
 font-size: 13pt;
}

li.p3:before{
 content:"> ";
 font-size: 13pt;
}

p.p4 {
 font-size: 13pt;
 margin-top: 1pt;
 margin-bottom: 1pt;	
}

p.p5 {
 font-size: 10pt;
 padding-top: 26.6666px;
}

p.p6 {
 font-size: 16pt; 
 margin-top: 13.3333px;
}

p.p6 a:hover{
	background-color: #eee;
}

.text.rea.hidden {
	display: none;
}
	
.text.about.hidden {
	display: none;
}

.text.pp.hidden {
	display: none;
}

.text.cnstrcn.hidden {
	display: none;
}

.text.nts.hidden {
	display: none;
}

.text.rnd.hidden {
	display: none;
}

.text.tlf.hidden {
	display: none;
}

.ccc{
	grid-area: c;
}

.rea{
 	grid-area: r;
}

.pp1{
 	grid-area: p;
}

.pp2{
	grid-area: q;
}

.cnstrcn1{
	grid-area: t;
}

.cnstrcn2{
	grid-area: n;
}

.nts{
	grid-area: o;
}

.rnd {
    grid-area: d;
}

.tlf{
	grid-area: l;
}

.tlfmp{
	grid-area: m;
}

.about{
	grid-area: a;
}

@media only screen and (min-width: 0px)  {
	.grid{
		display: grid;
		grid-column-gap: 0.5%;
		grid-row-gap: 10px;
		grid-template-columns: 10.25% 9.625% 19.25% 19.25% 19.25% 9.625% 10.25%;
		grid-template-areas:
        ". . . . . . ."
        ". . . . . . ."       
        ". m m m m . ."                 
        ". . . . . . ."      
		". . . . . . ." 		           
		". . . d d d ."
        ". . . . . . ."      
        ". . . . . . ."           
        ". . . . . . ."                 
		"c c c c c c c"
		". . . . . . ."
        ". . . . . . ."      
        ". . . . . . ."      
        ". . . . . . ."               
		". . . . . . ."
		". r r r . . ."
		". . . . . . ."
		". . . p p . ."
		". . . . . . ."
		". . . . . . ."
		". . . . . . ."
		". . t t . . ."
		". . . . . . ."
		". . . . . . ."
		". . o o o o o"
		". . . . . . ."
		". . . . . . ."
		". . . . . . ."
		"a . . . . . .";
		margin: 0 auto;
		z-index: 1;
		background-color: transparent;
		top: 25px;
	}
	.text.about.visible {
	    grid-column-start: 2;
		grid-column-end:7;
	    grid-row-start: 2;
		grid-row-end: 10;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 15px 30px 15px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.rea.visible {
		grid-column-start: 2;
		grid-column-end:7;
		grid-row-start: 2;
		grid-row-end: 8;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 15px 30px 15px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}	
	.text.pp.visible {
		grid-column-start: 2;
		grid-column-end:7;
		grid-row-start: 2;
		grid-row-end: 5;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 15px 30px 15px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}	
	.text.cnstrcn.visible {
		grid-column-start: 2;
		grid-column-end:7;
		grid-row-start: 2;
		grid-row-end: 5;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 15px 30px 15px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.nts.visible {
		grid-column-start: 2;
		grid-column-end:7;
		grid-row-start: 2;
		grid-row-end: 8;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 15px 30px 15px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.rnd.visible {
	    grid-column-start: 2;
		grid-column-end:7;
	    grid-row-start: 2;
		grid-row-end: 8;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}   	
	.text.tlf.visible {
	    grid-column-start: 2;
		grid-column-end:7;
	    grid-row-start: 2;
		grid-row-end: 8;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 15px 30px 15px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}   		
	.slideshow-container-wide {
	  max-height: 400px;
	  line-height: 400px;
	}
}

@media only screen and (min-width: 620px)  {
	.grid{
		display: grid;
		grid-gap: 5px;
		grid-template-columns: repeat(20, [col] 4.7% ) ;
		grid-template-rows: repeat(80, [row] 25px  );	
		grid-template-areas:
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . l l l l l l l l l l l l l l . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . m m m m m m m m . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
        ". . . . . . . . . . . . . . . . . . . ."         
		". . d d d d d d . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."	
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . c c c c c c c c c . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . r r r r . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."		
		". . . . . . . . . . . . . . . . . . . ."		
		". . . . . . . . . . . . . . q q q q . ."
		". . . . . . . . . . . p p p p . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". t t t t t . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . n n n n n . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . o o o o o o . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". a . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . .";
		margin: 0 auto;
		z-index: 1;
		background-color: transparent;
		top: 25px;
	}
	.text.about.visible {
		grid-column: col 3 / span 15;
		grid-row: row 1 / span 60;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.rea.visible {
		grid-column-start: 1;
		grid-column-end:20;
		grid-row: row 1 / span 44;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.pp.visible {
		grid-column-start: 1;
		grid-column-end:20;
		grid-row: row 1 / span 44;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.cnstrcn.visible {
		grid-column-start: 1;
		grid-column-end:20;
		grid-row: row 1 / span 36;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.nts.visible {
		grid-column-start: 1;
		grid-column-end:20;
		grid-row: row 1 / span 46;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.rnd.visible {
	    grid-column-start: 1;
		grid-column-end:20;
		grid-row: row 1 / span 38;        
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}  	
	.text.tlf.visible {
	    grid-column-start: 1;
		grid-column-end:20;
		grid-row: row 1 / span 52;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	} 		
	.slideshow-container-wide {
	  max-height: 800px;
	  line-height: 800px;
	}
}

@media only screen and (min-width: 960px)  {
	.grid{
		display: grid;
		grid-gap: 5px;
		grid-template-columns: repeat(20, [col] 4.7% ) ;
		grid-template-rows: repeat(100, [row] 25px  );	
		grid-template-areas:
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . l l l l l l l l l l l l . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . m m m m m m m m . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
        ". . . . . . . . . . . . . . . . . . . ."         
		". . d d d d d d . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."	
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . c c c c c c c c . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . r r r r . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."		
		". . . . . . . . . . . . . . . . . . . ."		
		". . . . . . . . . . . . . . . q q q q ."
		". . . . . . . . . . . . p p p p . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". t t t t t . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . n n n n n . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . o o o o o o . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". a . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . .";
		margin: 0 auto;
		z-index: 1;
		background-color: transparent;
		top: 25px;
	}
	.text.about.visible {
		grid-column: col 5 / span 12;
		grid-row: row 1 / span 56;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.rea.visible {
		grid-column: col 5 / span 12;
		grid-row: row 1 / span 46;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.pp.visible {
		grid-column: col 5 / span 12;
		grid-row: row 1 / span 44;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.cnstrcn.visible {
		grid-column: col 5 / span 12;
		grid-row: row 1 / span 38;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.nts.visible {
		grid-column: col 3 / span 16;
		grid-row: row 1 / span 44;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.rnd.visible {
		grid-column: col 6 / span 10;
		grid-row: row 1 / span 39;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}  	
	.text.tlf.visible {
		grid-column: col 3 / span 16;
		grid-row: row 1 / span 50;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	} 		
	.slideshow-container-wide {
	  max-height: 800px;
	  line-height: 800px;
	}
}

@media only screen and (min-width: 1240px)  {
	.grid{
		display: grid;
		grid-gap: 5px;
		grid-template-columns: repeat(20, [col] 4.7% ) ;
		grid-template-rows: repeat(115, [row] 25px  );	
		grid-template-areas:
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . l l l l l l l l l l l l . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . m m m m m m m m . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
        ". . . . . . . . . . . . . . . . . . . ."         
		". . d d d d d d . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."	
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . c c c c c c c c . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . r r r r . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."		
		". . . . . . . . . . . . . . . . . . . ."		
		". . . . . . . . . . . . . . . q q q q ."
		". . . . . . . . . . . . p p p p . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". t t t t t . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . n n n n n . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . o o o o o o . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . ."
		". a . . . . . . . . . . . . . . . . . ."
		". . . . . . . . . . . . . . . . . . . .";
		margin: 0 auto;
		z-index: 1;
		background-color: transparent;
		top: 25px;
	}
	.text.about.visible {
		grid-column: col 5 / span 12;
		grid-row: row 1 / span 54;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.rea.visible {
		grid-column: col 6 / span 10;
		grid-row: row 1 / span 44;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.pp.visible {
		grid-column: col 6 / span 10;
		grid-row: row 1 / span 43;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.cnstrcn.visible {
		grid-column: col 6 / span 10;
		grid-row: row 1 / span 37;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.nts.visible {
		grid-column: col 4 / span 14;
		grid-row: row 1 / span 42;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.rnd.visible {
		grid-column: col 6 / span 10;
		grid-row: row 1 / span 38;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}    	
	.text.tlf.visible {
		grid-column: col 4 / span 14;
		grid-row: row 1 / span 48;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	} 		
	.slideshow-container-wide {
	  max-height: 800px;
	  line-height: 800px;
	}
}


@media only screen and (min-width: 1550px)  {
	.grid{
		display: grid;
		grid-gap: 5px;
		grid-template-columns: repeat(20, [col] 4.7% ) ;
		grid-template-rows: repeat(140, [row] 25px);	
		grid-template-areas:
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."	
	". . l l l l l l l l l l l l l l l l . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."	
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . m m m m m m m m m m ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."		
	". . . . . . . . . . . . . . . . . . . ."		
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."						
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."	
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."	
	". . . . . . . . . . . . . . . . . . . ."		
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."	
	". d d d d d d . . . . . . . . . . . . ."	
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ." 
	". . . . . . . . . . . . . . . . . . . ." 					
	". . . . . . . . . c c c c c c c c c c ."	
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."	
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."	
	". . . . . . . . . . . . . . . . . . . ."	
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."			
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."	
	". . . . r r r r . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."		
	". . . . . . . . . . . . . . q q q q . ."
	". . . . . . . . . . . p p p p . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."	
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". t t t t t . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . n n n n n . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . o o o o o o o o ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."		
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ."	
	". . . . . . . . . . . . . . . . . . . ."		
	". . . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . ." 
	". . . . . . . . . . . . . . . . . . . ." 	
	". . . . . . . . . . . . . . . . . . . ." 		
	". . . . . . . . . . . . . . . . . . . ."		
	". . . . . . . . . . . . . . . . . . . ."
	". a . . . . . . . . . . . . . . . . . ."
	". . . . . . . . . . . . . . . . . . . .";
		margin: 0 auto;
		z-index: 1;
		background-color: transparent;
		top: 25px;
	}
	.text.about.visible {
		grid-column: col 5 / span 12;
		grid-row: row 1 / span 52;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.rea.visible {
		grid-column: col 6 / span 10;
		grid-row: row 1 / span 44;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.pp.visible {
		grid-column: col 6 / span 10;
		grid-row: row 1 / span 43;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.cnstrcn.visible {
		grid-column: col 6 / span 10;
		grid-row: row 1 / span 37;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.nts.visible {
		grid-column: col 4 / span 14;
		grid-row: row 1 / span 42;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	}
	.text.rnd.visible {
		grid-column: col 6 / span 10;
		grid-row: row 1 / span 38;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	} 	
	.text.tlf.visible {
		grid-column: col 4 / span 14;
		grid-row: row 1 / span 48;
		height: 100%;
		z-index: 15;
		position: relative;
		padding: 30px 40px 30px 40px;
		background: #eee;
		cursor: auto;
		z-index: 10;
	} 	
	.slideshow-container-wide {
	  max-height: 800px;
	  line-height: 800px;
	}
}

* {box-sizing:border-box}

.slideshow-container {
  max-width: 533px;
  position: relative;
  margin: 13.3333px auto;
}

.slideshow-container-wide {
  height: 100%;
  position: relative;
  margin: 13.3333px auto;
  text-align: center;
}

.imgSlide {
 	width: 100%
}

.imgSlideWide {
 	max-height: 100%;
 	max-width: 100%;
 	vertical-align: middle; 
}

.slides {
    display: none;
    margin: 0 auto;
}

.slides.wide {
    display: none;
    margin: 0 auto;
    height: 100%;
    width: 100%;
}

.prev, .next {
  display: inline-block;
  cursor: pointer;
  width: auto;
  padding: 1px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  color: #555;
}

.prev:hover, .next:hover {
	color: #999;
}

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 1px 2px;
  background-color: #999;
  border-radius: 50%;
  display: inline-block;
}

.active, .dot:hover {
  background-color: #555;
}

#loader{
	visibility: visible;
}

#circle {
	margin-left: -30px;
	margin-top: 13.3333px;
	left: 50%;
	-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
			transform: translateX(-50%);
	position: relative;
	z-index: 1;
	width: 62px;
	height: 62px;
	border: 5px solid #ccc;
	border-radius: 50%;
	border-top: 5px solid #999;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

.loader{
	display: block;
	position: absolute;
    background-color: #eee;
    padding-top: 13.3333px;
    top: 0px; 
    bottom: -50px;
	width: 100%;
	opacity: 1;
    z-index: 999;
    margin: 0 auto;
	left: 50%;
	-webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	        transform: translateX(-50%);
	font-family: 'Roboto Mono', monospace;
	text-align: center;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

    
</style>

