import Vue from 'vue';
import App from './App.vue';
import imagesLoaded from 'vue-images-loaded';


Vue.config.productionTip = false;

var app = new Vue({
    render: h => h(App),
}).$mount('#app')
